/* Time.css */

/* Estilo para o container do carrossel */
.time-carousel-container {
  margin: 20px auto;
  text-align: center;
  width: 100%;
}

/* Estilo para o carrossel em si */
.time-carousel {
  display: flex;
  justify-content: flex-start; /* Alinha os itens à esquerda por padrão */
  gap: 32px; /* Aumenta o espaço entre os itens */
  padding: 20px 0;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: auto; /* Permite rolagem horizontal */
}

/* Estilo para cada item do produto */
.produto-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  flex-shrink: 0; /* Impede que os itens encolham */
}

/* Estilo para as imagens (escudos) */
.time-carousel img {
  width: 120px; /* Aumentado para desktop */
  height: 120px; /* Aumentado para desktop */
  object-fit: cover; /* Alterado de 'contain' para 'cover' */
  object-position: center; /* Centraliza a imagem */
  border-radius: 50%;
}

/* Estilo para o texto abaixo da imagem */
.produto-item p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #69c4ee;
}

/* Efeito de hover nos itens */
.produto-item:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Esconde a barra de rolagem no navegador */
.time-carousel::-webkit-scrollbar {
  display: none;
}

/* Melhora o comportamento do scroll no desktop */
@media (min-width: 768px) {
  .time-carousel {
    justify-content: center; /* Centraliza os itens no desktop */
    white-space: nowrap; /* Evita que os itens quebrem linha */
  }

  .time-carousel img {
    flex-shrink: 0; /* Impede que as imagens encolham */
  }
}

/* Ajustes para mobile */
@media (max-width: 767px) {
  .time-carousel {
    gap: 16px; /* Reduz o espaço entre os itens no mobile */
    padding-left: 20px; /* Adiciona padding à esquerda para o primeiro item não ficar colado */
  }

  .time-carousel img {
    width: 70px; /* Tamanho menor para mobile */
    height: 70px; /* Tamanho menor para mobile */
  }

  .produto-item p {
    font-size: 14px; /* Tamanho de fonte menor para mobile */
  }

  /* Garante que o primeiro item fique alinhado à esquerda */
  .time-carousel::after {
    content: '';
    flex: 0 0 20px; /* Adiciona um espaço no final para garantir que o último item não fique colado */
  }
}