.categorias {
  position: relative;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  font-size: 30px;
  color: #d50d27;
}

.categorias-dropdown {
  position: absolute;
  top: -71px;
  right: -20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px; /* Aumenta a largura da caixa */
  min-height: 250px; /* Define uma altura mínima */
  padding: 25px; /* Aumenta o espaçamento interno */
  z-index: 2000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Mantém os itens organizados na vertical */
  align-items: flex-start; /* Alinha os itens à esquerda */
}

.categorias-dropdown ul {
  width: 100%;
  display: flex;
  flex-direction: column; /* Garante que os itens fiquem um abaixo do outro */
  gap: 15px; /* Ajusta o espaçamento entre os itens */
  padding: 0;
}

.categorias-dropdown li {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Alinha os itens à esquerda */
  padding: 12px 0;
}
.categorias-dropdown li:last-child {
  border-bottom: none;
}

.categorias-dropdown li {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Espaço entre o texto e o ícone */
  align-items: center; /* Alinha verticalmente os itens */
  padding: 12px 0;
}

.categorias-dropdown li a {
  color: #3f60ec;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 10px;
  width: 100%; /* Garante que o link ocupe toda a linha */
}

.categorias-dropdown li a svg {
  font-size: 20px; /* Ajusta o tamanho do ícone */
  color: #3f60ec;
  margin-left: auto; /* Empurra o ícone para a direita */
}

/* Remove a barra de rolagem global */
body {
  overflow-y: auto; /* Garante que a barra de rolagem global funcione */
}
