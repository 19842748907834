.produto-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Muda para coluna para empilhar as seções */
  align-items: center; /* Centraliza horizontalmente os filhos */
}

.produto-detalhes {
  width: 100%; /* Certifique-se de que ocupa 100% da largura do container */
  display: flex;
  gap: 30px;
  background-color: #fff;
  border: 1px solid #ddd ;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.produto-imagem-maior {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 600px; /* Definindo altura máxima para a imagem principal */
  overflow: hidden; /* Oculta parte da imagem que excede o container */
}

.produto-imagem {
  width: 100%; /* Garante que a imagem preencha a largura do container */
  height: 100%; /* Garante que a imagem preencha a altura do container */
  object-fit: cover; /* Faz a imagem cobrir o container sem distorcer */
  border-radius: 10px;
}

.produto-imagens-adicionais {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.produto-imagem-pequena {
  width: 100px; /* Largura fixa para miniaturas */
  height: 100px; /* Altura fixa para miniaturas */
  object-fit: cover; /* Faz a imagem cobrir o container sem distorcer */
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.produto-imagem-pequena.active,
.produto-imagem-pequena:hover {
  border-color: #000000;
}

.produto-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;
}

.produto-info h1 {
  font-size: 32px;
  color: #69c4ee;
  margin-top: 60px; /* Reduzi para aproximar o preço */
}

.produto-info .preco {
  font-size: 28px;
  color: #f2699c;
  font-weight: bold;
  margin-bottom: 20px;
}


.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center; /* Centraliza o conteúdo */
  gap: 10px;
  margin-bottom: 20px;
  width: 100%; /* Garante que o contêiner ocupe toda a largura disponível */
  text-align: center; /* Centraliza o texto dentro do contêiner */
  font-family: 'Poppins', sans-serif; 
}

.produto-container .old-price {
  text-decoration: line-through;
  color: #a0a0a0;
  font-size: 18px;
  font-family: 'Poppins', sans-serif; 
}

.produto-container .current-price {
  color: #f2699c;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Poppins', sans-serif; 
}

.tabela-medidas {
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tabela-medidas-texto {
  margin-right: 8px;
  font-weight: bold;
  color: #2fdd82;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);  /* Diminui a opacidade do fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: auto;  /* Permite rolar o conteúdo do modal se necessário */
}

.modal-content {
  position: relative;
  max-width: 90%;  /* Limita a largura da imagem dentro do modal */
  max-height: 90%;  /* Limita a altura da imagem dentro do modal */
  overflow: hidden;  /* Impede que a imagem ultrapasse os limites do modal */
  color: #ffffff;
}

.close-medidas {
  position: absolute;
  top: 0px;
  right: -15px;
  font-size: 40px;
  color: #ffffff; /* Cor branca */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 9000; /* Garante que o botão esteja acima da imagem */
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.8); /* (Opcional) Adiciona um leve brilho ao redor */
}

.modal img {
  width: 100%;  /* Garante que a imagem ocupe toda a largura disponível */
  height: auto;  /* Mantém a proporção da imagem */
  display: block;
  object-fit: contain;  /* Garante que a imagem se ajuste dentro da área disponível sem distorcer */
  max-height: 100vh;  /* A imagem pode ocupar até 100% da altura da tela, com rolagem se necessário */
  color: #ffffff;
}


.produto-descricao {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-top: 15px;
}

.ver-mais-btn {
  background: none;
  color: #000000;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.ver-mais-btn:hover {
  color: #000000;
  background-color: #fff;
}

.add-to-cart-btn {
  padding: 15px 30px;
  background-color: #69c4ee;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  margin-bottom: 20px;
}

.add-to-cart-btn:hover {
  background-color: #7bbbd8;
  transform: scale(1.05);
}

.formas-pagamento {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo na horizontal */
  text-align: center; /* Centraliza o texto */
}

.formas-pagamento p {
  font-size: 16px;
  color: #3f60ec;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.icones-pagamento {
  display: flex;
  gap: 15px;
}

.icone-pagamento {
  font-size: 36px;
  color: #3f60ec;
}

.tamanhos-disponiveis {
  margin-top: 20px;
  margin-bottom: 40px;
}

.tamanhos-disponiveis h3 {
  font-size: 20px;
  color: #69c4ee;
  margin-bottom: 10px;
}

.tamanhos {
display: inline-flex;
  grid-template-columns: repeat(5, 1fr); /* Exibe 5 tamanhos por linha */
  gap: 10px;
  justify-content: center;
}

.tamanho {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  white-space: nowrap;
  text-align: center; /* Centraliza o conteúdo dos tamanhos */
}

.tamanho.selected {
  background-color: #69c4ee;
  color: #fff;
  border: 1px solid #69c4ee;
}

.tamanho.indisponivel {
  text-decoration: line-through;
  color: grey;
  pointer-events: none;
}

.produto-sugerido-container {
  margin-top: 40px;
  text-align: center;
  width: 100%; /* Garante que o container ocupe 100% da largura */
}

.produto-sugerido-container h2 {
  font-size: 24px;
  color: #69c4ee;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.carrossel {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Garante que as setas e produtos fiquem na mesma linha */
  width: 100%; /* Garante que o carrossel ocupe 100% da largura */
}

.carrossel-seta {
  font-size: 36px; /* Aumenta o tamanho do botão */
  background: none;
  border: none;
  cursor: pointer;
  color: #69c4ee;
  padding: 10px; /* Adiciona mais padding para tornar o botão maior */
  transition: transform 0.3s, color 0.3s; /* Animação suave para o efeito de aumento e mudança de cor */
  margin: 0 20px; /* Aumenta o espaçamento entre as setas */
}

.carrossel-seta:hover {
  transform: scale(1.2); /* Aumenta o tamanho ao passar o mouse */
  color: #69c4ee; /* Muda a cor da seta ao passar o mouse */
}

.carrossel-produtos {
  display: flex;
  gap: 30px; /* Aumenta o espaço entre os itens */
  flex: 1; /* Permite que a lista de produtos ocupe o espaço disponível */

}

.carrossel-item {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 220px; /* Aumenta a largura dos itens do carrossel */
  transition: transform 0.3s; /* Animação suave para o efeito de hover */
}

.carrossel-item:hover {
  transform: scale(1.05); /* Aumenta o tamanho do item ao passar o mouse */
}

.carrossel-imagem {
  width: 100%;
  height: 180px; /* Aumenta a altura da imagem */
  object-fit: cover;
  border-radius: 8px;
}

.carrossel-item h3 {
  font-size: 18px; /* Aumenta o tamanho da fonte do nome do produto */
  margin-top: 10px;
  color: #69c4ee;
}

.carrossel-item .price {
  font-size: 16px; /* Aumenta o tamanho da fonte do preço */
  color: #f2699c;
  font-weight: bold;
}
.personalizacao-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.personalizacao-container label {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 30px;
  transition: all 0.3s ease;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
}

.personalizacao-container input[type="checkbox"] {
  display: none;
}

.personalizacao-container input[type="checkbox"]:checked + label {
  background-color: #2fdd82;
  color: #fff;
  border-color: #2fdd82;
}

.personalizacao-container label:hover {
  background-color: #f5f5f5;
}

.personalizacao-inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.personalizacao-inputs input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
}

.personalizacao-inputs input:focus {
  outline: none;
  border-color: #2fdd82;
}


/* Estilos para mobile */
@media only screen and (max-width: 768px) {
  .produto-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .produto-detalhes {
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }

  .produto-imagens-adicionais {
    flex-direction: row;
    gap: 5px;
    overflow-x: auto;
    justify-content: center;
    width: 100%;
  }

  .produto-imagem-pequena {
    width: 60px;
    height: 60px;
  }

  .produto-imagem-maior {
    width: 100%;
    max-height: 300px;
  }

  .produto-imagem {
    max-height: 300px;
    object-fit: contain;
  }

  .produto-info h1 {
    font-size: 24px;
    margin-top: -80px;
  }

  .produto-info .preco {
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .price-container {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* Evita quebra de linha */
  }

  .old-price {
    font-size: 11px; 
    margin-right: 0px; 
    white-space: nowrap;
  }

  .current-price {
    font-size: 15px; 
    font-weight: bold;
    white-space: nowrap;
  }

  .produto-descricao {
    font-size: 14px;
    padding: 0;
  }

  .ver-mais-btn {
    font-size: 14px;
  }

  .add-to-cart-btn {
    font-size: 16px;
    padding: 8px -1px; /* Ajusta o padding para torná-lo mais compacto */
    border-radius: 25px; /* Faz o botão mais circular */
 
    border: none; /* Remove a borda */
    cursor: pointer; /* Muda o cursor para pointer */
    transition: background-color 0.3s, transform 0.3s; /* Transição suave */
    margin: 25px; /* Adiciona margem para espaçamento */
    margin-top: 0px;
  }

  .add-to-cart-btn:hover {

    transform: scale(1.05); /* Efeito de aumento ao passar o mouse */
  }

  .tamanhos-disponiveis {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .tamanhos {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Reduz o espaço entre as caixinhas */
    justify-content: center;
  }

  .tamanho {
    width: 20%; /* Ajusta a largura para 4 tamanhos por linha */
    padding: 8px 5px; /* Ajusta o padding */
    font-size: 12px; /* Reduz o tamanho da fonte */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 20px; /* Faz os tamanhos mais circulares */
    background-color: #f9f9f9;
    white-space: normal; /* Permite que o texto quebre em várias linhas */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Centraliza a seção de Formas de Pagamento */
  .formas-pagamento {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo na horizontal */
    text-align: center; /* Centraliza o texto */
    margin-top: 20px;
  }

  .formas-pagamento p {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: -8px;
    font-family: 'Poppins', sans-serif;
  }

  /* Centraliza os ícones */
  .icone-pagamento {
    font-size: 30px;
    display: inline-block;
    text-align: center;
  }

  /* Centraliza todos os ícones */
  .icone-pagamento-container {
    display: flex;
    justify-content: center; /* Centraliza os ícones */
    gap: 0px; /* Espaçamento entre os ícones */
    flex-wrap: wrap; /* Garante que eles se ajustem em telas menores */
  }

  .produto-detalhes {
    padding: 30px; /* Aumenta o espaço interno do container */
     border-radius: 40px; /* Bordas arredondadas */
    margin-bottom: -50px; /* Espaçamento inferior para afastar de outros elementos */
}


  .carrossel {
    flex-direction: row; /* Mantém os itens em linha */
    align-items: center; /* Centraliza os itens */
  }

  .carrossel-produtos {
    flex-wrap: wrap; /* Permite que os itens se ajustem em várias linhas */
    justify-content: center; /* Centraliza os itens */
    gap: 15px; /* Reduz o espaço entre os itens */
  }

  .carrossel-item {
    width: 100px; /* Ajusta a largura para dispositivos móveis */
  }

  .carrossel-imagem {
    height: 120px; /* Reduz a altura da imagem */
  }

  .carrossel-seta {
    font-size: 28px; /* Reduz o tamanho das setas */
    padding: 5px; /* Ajusta o padding das setas */
    margin: 0 10px; /* Adiciona margem para espaçamento */
    border-radius: 50%; /* Torna o botão circular */
    background-color:none; /* Fundo do botão */
    transition: background-color 0.3s, transform 0.3s; /* Suaviza a transição */
  }

  .carrossel-seta:hover {
    background-color: none; /* Cor de fundo ao passar o mouse */
    transform: scale(1.1); /* Efeito de hover nas setas */
  }
  .personalizacao-container {
    flex-direction: column; /* Organiza os elementos verticalmente */
    align-items: center; /* Centraliza os elementos no centro */
    text-align: center; /* Centraliza o texto */
    padding: 5px; /* Adiciona um pouco de espaçamento interno */
  }
  
  .personalizacao-container label {
    padding: 14px 30px; /* Botões mais confortáveis */
    font-size: 16px; /* Tamanho de fonte para legibilidade */
    border-radius: 30px; /* Bordas arredondadas */
    margin: 10px 0; /* Espaçamento vertical entre os botões */
    width: 80%; /* Preenche grande parte da tela */
    max-width: 300px; /* Define um limite de largura */
    background-color: #ffffff; /* Cor de fundo padrão */
    border: 2px solid #ddd; /* Borda suave */
    color: #333; /* Cor do texto */
    font-weight: bold; /* Texto mais destacado */
    text-align: center;
    transition: all 0.3s ease; /* Transições suaves para interações */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra para profundidade */
    cursor: pointer; /* Muda o cursor para pointer */
  }
  
  .personalizacao-container label:hover {
    background-color: #f8f8f8; /* Cor de fundo ao passar o mouse */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Sombra mais forte */
    transform: scale(1.02); /* Leve aumento */
  }
  
  .personalizacao-container input[type="checkbox"]:checked + label {
    background-color: #2fdd82; /* Cor de destaque ao selecionar */
    color: #fff; /* Texto branco */
    border-color: #2fdd82; /* Borda correspondente */
    transform: scale(1.05); /* Efeito de aumento */
    box-shadow: 0px 6px 10px rgba(47, 221, 130, 0.3); /* Sombra com a cor de destaque */
  }
  
  .personalizacao-container label:active {
    transform: scale(0.98); /* Reduz ligeiramente ao clicar */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* Reduz a sombra ao pressionar */
  }
}
