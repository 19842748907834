.produtos-filtrados {
  padding: 20px;
}

.produtos-filtrados h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #494949;
}

/* Grid para os produtos */
.produtos-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas no desktop */
  gap: 20px; /* Espaçamento entre os itens */
  justify-content: center; /* Centraliza a grid */
  max-width: 1200px; /* Largura máxima do container */
  margin: 0 auto; /* Centraliza horizontalmente */
  padding: 20px;
}

/* Remover o sublinhado do Link */
.produto-card a {
  text-decoration: none; /* Remove o sublinhado */
  color: inherit; /* Garante que a cor do texto seja herdada (para o nome do produto e preço) */
}

/* Ajuste para telas menores (mobile) */
@media (max-width: 600px) {
  .produtos-grid {
    grid-template-columns: repeat(2, 1fr); /* Exibe 2 colunas por linha no mobile */
    gap: 10px; /* Espaçamento reduzido no mobile */
  }
}

/* Estilo de cada produto */
.produto-card {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out; /* Efeito de hover */
}

/* Efeito de hover - aumenta o tamanho e suaviza a sombra */
.produto-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Sombra mais forte no hover */
}

/* Estilo da imagem do produto */
.produto-card img {
  width: 100%;
  height: auto;
  max-height: 200px; /* Limita a altura da imagem */
  object-fit: cover; /* Mantém o aspecto da imagem */
  border-radius: 8px;
}

/* Estilo do nome do produto */
.produto-card h2 {
  font-size: 16px;
  margin: 10px 0;
  color: #3f60ec; /* Cor do texto */
  font-family: 'Poppins', sans-serif;
}

/* Estilo do preço */
.produto-card p {
  font-size: 14px;
  color: #000000; /* Cor mais escura para o preço */
  font-family: 'Poppins', sans-serif;
  margin-top: 5px;
}

/* Estilo do preço destacado */
.produto-card .price {
  font-size: 18px;
  color: #3f60ec; /* Cor destacada para o preço */
  font-weight: bold;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}