.pedidos-container {
  max-width: 600px; /* Limitar a largura da página */
  margin: 40px auto; /* Centraliza verticalmente e horizontalmente */
  padding: 20px;
  background-color: #ffffff; /* Fundo branco para melhor contraste */
  border-radius: 12px; /* Borda mais arredondada */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adiciona sombra suave */
  font-family: 'Poppins', sans-serif; /* Fonte alterada */
}

.pedidos-container h2 {
  background-color: #fff;
  text-align: center;
  color: #333; /* Cor mais suave para o título */
  margin-bottom: 20px; /* Espaço abaixo do título */
}

.pedidos-container input {
  width: calc(100% - 20px); /* Largura total menos padding */
  padding: 12px; /* Aumenta o padding */
  margin-bottom: 15px; /* Espaço maior entre o input e o botão */
  border: 1px solid #ccc; /* Borda cinza mais suave */
  border-radius: 8px; /* Borda arredondada */
  transition: border 0.3s; /* Efeito de transição na borda */
}

.pedidos-container input:focus {
  border-color: #000000; /* Cor da borda ao focar */
  outline: none; /* Remove a borda padrão do navegador */
}

.pedidos-container button {
  width: auto; /* Largura automática */
  padding: 10px 20px; /* Tamanho consistente, padding horizontal */
  background-color: #1f1e1e; /* Nova cor do botão */
  color: #fff; /* Texto branco */
  border: none; /* Remove a borda padrão */
  border-radius: 8px; /* Borda arredondada */
  cursor: pointer;
  font-size: 16px; /* Aumenta o tamanho da fonte */
  transition: background-color 0.3s; /* Efeito de transição */
  display: block; /* Para centralizar */
  margin: 0 auto; /* Centraliza horizontalmente */
}

.pedidos-container button:hover {
  background-color: #2e2f30; /* Azul mais escuro ao passar o mouse */
}

.pedidos-list {
  margin-top: 20px;
}

.pedidos-list ul {
  list-style-type: none; /* Remove a bolinha padrão */
  padding: 0; /* Remove o padding padrão da lista */
  margin: 0; /* Remove a margem padrão da lista */
}

.pedido-item {
  border-radius: 8px; /* Remove a borda */
  padding: 15px; /* Aumenta o padding */
  margin-bottom: 15px; /* Espaço maior entre itens */
  background-color: #f9f9f9; /* Fundo claro */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adiciona sombra suave */
}

.pedido-item h3 {
  margin: 0 0 10px; /* Espaço abaixo do título do pedido */
}

.pedido-item-detail {
  margin: 5px 0; /* Mantém espaçamento vertical entre itens */
  font-size: 14px; /* Tamanho de fonte legível */
}

.message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  .pedidos-container {
    padding: 15px; /* Ajusta o padding */
  }

  .pedidos-container h2 {
    font-size: 20px; /* Tamanho da fonte do título */
  }

  .pedidos-container input {
    padding: 10px; /* Ajusta o padding do input */
  }

  .pedidos-container button {
    padding: 10px 15px; /* Ajusta o padding do botão */
  }

  .pedido-item {
    padding: 10px; /* Ajusta o padding do item do pedido */
  }

  .pedido-item h3 {
    font-size: 18px; /* Tamanho da fonte do título do pedido */
  }
}
