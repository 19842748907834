/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #ffffff;
}

.app {
  text-align: center;
}

.header {
  display: flex;
  justify-content: center; /* Centraliza a logo */
  align-items: center;
  padding: 10px 20px;
  background-color: #fbdaca;
  color: #fff;
  position: relative; /* Define como referência para os elementos absolutos */
}

.texto-rotativo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbdaca;
  color:  #3f60ec;
  font-size: 6px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  position: relative;
  padding: 10px 0;
  height: 50px; /* Ajusta a altura para garantir o alinhamento correto */
}

.prev-button,
.next-button {
  background: none;

  color: #3f60ec;
  font-size: 16px;
  padding: 8px;
  font-weight: bold;
  cursor: pointer;
  position: absolute; /* Para sobrepor as setas ao conteúdo */
  top: 50%;
  transform: translateY(-50%); /* Centraliza verticalmente */
}

.prev-button {
  left: 400px; /* Posiciona a seta à esquerda */
  margin-top: -1px;
}

.next-button {
  right: 400px; /* Posiciona a seta à direita */
  margin-top: -1px;
}

.prev-button:hover,
.next-button:hover {
  background-color: #fbdaca;
  color: #3f60ec;
}

.categorias {
  display: block;
}


.nav .menu {
  display: none;
}


h1 {
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

h1 strong {
  font-weight: bold; /* Apenas o 'Site oficial' ficará em negrito */
}

h1 {
  font-weight: normal; /* O texto principal não ficará em negrito */
}

.linha-branca {
  width: 100%;
  border: none;
  border-top: 2px solid white;
  position: absolute;
  bottom: 0;
  left: 0;
}

.icons-left {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 20px; /* Posiciona os ícones à esquerda */
  font-size: 24px;
}

.icons-left a {
  color: #3f60ec; /* Define a cor branca especificamente para os links dentro de .icons-left */
  font-size: 26px;
}

.logo {
  height: 130px;
  display: flex;
  align-items: center;
}

.icons-right {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 20px; /* Posiciona os ícones à direita */
}



.nav {
  display: flex;
  align-items: center; /* Garante que todos os itens dentro de .nav estejam alinhados ao centro */
}

.menu {
  list-style: none;
  display: flex;
}

.menu li {
  margin: 0 10px;
}

.menu a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Poppins', sans-serif; 
}

.icons {
  display: flex;
  align-items: center; /* Alinha os ícones verticalmente ao centro */
  gap: 10px; /* Espaçamento entre os ícones */
}


.icons a {
  color: #d50d27;
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center; /* Alinha os ícones verticalmente ao centro */
   justify-content: center;
}

.menu-icon {
  display: none; /* Isso pode ser ajustado para dispositivos móveis */
  margin-top: 0px;
  padding: 0;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

.banner {
  width: 100%;
  max-height: 700px; /* Ajuste para controlar a altura máxima do banner */
  object-fit: cover;  /* Para garantir que a imagem se ajuste bem dentro da área definida */
  margin-top: 0,1px;
}

.products-title {
  font-size: 28px;
  margin: 40px 0 20px;
  color: #3f60ec;
  font-weight: bold;  
  font-family: 'Poppins', sans-serif;
background-color: #fff;
}


.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas de tamanho igual */
  gap: 20px; /* Espaçamento entre os itens */
  justify-content: center; /* Centraliza a grid */
  max-width: 1200px; /* Largura máxima do container */
  margin: 0 auto; /* Centraliza horizontalmente */
  padding: 20px;
}

.old-price {
  text-decoration: line-through;
  color: #a0a0a0;
  margin-right: 5px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif; 
}

.current-price {
  color: #3f60ec;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Poppins', sans-serif; 
}


/* Estilo para o botão "Ver Mais" */
.load-more {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 20px; /* Borda arredondada */
  padding: 8px 16px; /* Botão pequeno */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  margin: 20px auto;
  display: block;
}

.load-more:hover {
  background-color: #333; /* Efeito de hover para clarear o fundo */
}


.product-item {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efeito de sombra */
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 200px; /* Limita a altura da imagem */
  object-fit: cover; /* Mantém o aspecto da imagem */
  border-radius: 8px;
}

.product-item h3 {
  margin-top: 10px;
  font-size: 16px;
  color: #494949;
  font-family: 'Poppins', sans-serif;
}

.product-item p {
  margin-top: 5px;
  font-size: 14px;
  color: #000000;
  font-family: 'Poppins', sans-serif;
}

.product-item .price {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}
.cart-icon {
  background: none;
  border: none;
  font-size: 24px; /* Tamanho dos ícones */
  color: #d50d27;
  cursor: pointer;
  padding: 0; /* Remove o padding padrão */
  margin: 0; /* Remove o margin padrão */
   margin-top: 5px;
}

/* Estilos para o segundo banner */
.banner2 {
  width: 100%;
  max-height: 700px; /* Ajuste a altura para um banner médio */
  object-fit: cover;
  margin-top: 30px; /* Espaço entre a listagem de produtos e o segundo banner */
}

/* Estilos para as seções de informações com ícones */
.info-sections {
  display: flex;
  justify-content: space-around;
  margin: 40px 0; /* Espaçamento acima e abaixo das seções de informações */
}

.info-item {
  text-align: center;
  max-width: 200px;
}

.info-icon {
  font-size: 40px;
  color: #3f60ec;
  margin-bottom: 10px;
}

.info-item h3 {
  font-size: 20px;
  color: #d50d27;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.info-item p {
  font-size: 16px;
  color: #ff66c4;
  font-family: 'Poppins', sans-serif;
}
.mobile-banner {
  display: none; /* Esconde o vídeo por padrão */
}

/* Estilos para mobile */
@media only screen and (max-width: 768px) {

  .texto-rotativo {
    display: flex;
    align-items: center; /* Alinha verticalmente os itens ao centro */
    justify-content: space-between; /* Garante que as setas e o texto fiquem alinhados */
    font-size: 8px;
    height: 30px;
    padding: 5px 0;
    position: relative;
  }

  .prev-button,
  .next-button {
    font-size: 12px;
    padding: 5px;
    background: none; /* Remove o fundo das setas */
    border: none; /* Remove a borda das setas */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    flex-shrink: 0; /* Impede que as setas diminuam de tamanho */
  }

  .prev-button {
    margin-left: -350px; /* Ajusta o espaço entre a seta e o lado esquerdo */
  }

  .next-button {
    margin-right: -350px; /* Ajusta o espaço entre a seta e o lado direito */
  }

  h1 {
    font-size: 10px;
    margin: 0 10px; /* Ajusta o espaçamento ao redor do texto */
    text-align: center; /* Centraliza o texto */
    flex-grow: 1; /* Faz o texto ocupar o espaço disponível */
  }

  .linha-branca {
    border-top: 1px solid white;
    width: 100%;
    margin-top: 5px;
  }
   /* Header - Ícones e Logo */
   .header {
    flex-direction: row;
    justify-content: center; /* Centraliza a logo no mobile */
    align-items: center;
    padding: 5px 10px;
  }

  .logo {
    height: 100px; 
    margin: 0 auto; /* Centraliza a logo */
  }

  .icons-left {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    left: 10px; /* Ajusta posição para telas menores */
  }

  .icons-left a {
    color: #3f60ec;
   
  }

  .icons-right {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 10px; /* Ajusta posição para telas menores */
  }

  .icons-right a {
    color: #3f60ec;
   
  }

  .nav {
    flex-direction: row;
    align-items: center;
    justify-content: space-around; /* Espaça os itens do menu */
  }

  .categorias {
    display: block;
  }

  /* Esconde as categorias na barra de navegação */
  .nav .menu {
    display: none;
  }

  .menu {
    display: none; /* Esconde as categorias na barra de navegação principal */
  }

  .menu li {
    margin: 0 5px; /* Reduz o espaço entre os itens */
  }

  /* Ícones */
  .icons {
    display: flex;
    flex-direction: row;
    align-items: center; /* Alinha verticalmente os ícones */
    justify-content: space-between; /* Espaça horizontalmente os ícones */
    gap: 6px; /* Espaçamento entre os ícones */
    margin-top: 0;
  }

  /* Ajuste o tamanho dos ícones */
  .icons a {
    color: #3f60ec;
    font-size: 24px; /* Tamanho dos ícones */
    line-height: 1;
    display: flex;
    align-items: center; /* Centraliza verticalmente os ícones */
    justify-content: center; /* Centraliza horizontalmente os ícones */
  }
  .load-more {
    padding: 6px 12px; /* Reduz o tamanho do botão no mobile */
    font-size: 14px;
  }

  .price-container {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .old-price {
    font-size: 14px; 
    white-space: nowrap;
  }

  .current-price {
    font-size: 16px; 
    font-weight: bold;
    white-space: nowrap;
  }

/* Banner */
  .banner {
    content: url('./IMG/banner3.png');
    width: 100%;
    height: auto;
  }

  .banner2 {
    content: url('./IMG/banner4.png');
    width: 100%;
    height: auto;
}

  /* Destaques - Produtos */
  .product-list {
    grid-template-columns: repeat(2, 1fr); /* 2 colunas para dispositivos móveis */
    gap: 10px; /* Espaçamento reduzido */
  }

  .product-item {
    margin-bottom: 10px;
  }

  /* Seções de Informações */
  .info-sections {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .info-item {
    flex: 1;
    max-width: 120px;
    margin: 0 5px;
  }

  .info-icon {
    font-size: 24px;
  }

  .info-item h3 {
    font-size: 16px;
  }

  .info-item p {
    font-size: 14px;
  }

  /* Rodapé */
  .rodape {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
  }

  .rodape div {
    flex: 1 1 45%;
    margin: 5px;
    font-size: 14px;
  }
}


